<template>
  <div>
    <EditSection
      v-loading="steamInfo.loading"
      title="Steam评分"
      :is-edit="steamInfo.isEdit"
      @edit="handleSteamEdit"
      @confirm="handleSteamConfirm"
      @cancel="handleSteamCancel"
    >
      <el-form
        :model="steamInfo.data"
        label-width="100px"
        style="width: 450px"
      >
        <el-button
          :loading="updateLoading"
          plain
          style="margin-left: 30px; width: 160px;"
          @click="handleUpdateSteamInfo"
        >
          重新爬取(异步任务)
        </el-button>
        <el-form-item label="总评分数：">
          <template v-if="steamInfo.isEdit">
            <el-input-number
              v-model="steamInfo.data.appraiseTotalNum"
              :min="0"
              :step="1"
              step-strictly
            />
          </template>
          <template v-else>
            <span>{{ gameDetailBasic.appraiseTotalNum || '/' }}</span>
          </template>
        </el-form-item>
        <el-form-item label="好评数：">
          <template v-if="steamInfo.isEdit">
            <el-input-number
              v-model="steamInfo.data.appraiseGoodNum"
              :min="0"
              :step="1"
              step-strictly
            />
          </template>
          <template v-else>
            <span>{{ gameDetailBasic.appraiseGoodNum || '/' }}</span>
          </template>
        </el-form-item>
        <el-form-item label="差评数：">
          <template v-if="steamInfo.isEdit">
            <el-input-number
              v-model="steamInfo.data.appraiseBadNum"
              :min="0"
              :step="1"
              step-strictly
            />
          </template>
          <template v-else>
            <span>{{ gameDetailBasic.appraiseBadNum || '/' }}</span>
          </template>
        </el-form-item>
      </el-form>
    </EditSection>

    <EditSection
      v-loading="netboomInfo.loading"
      title="Netboom评分"
      :is-edit="netboomInfo.isEdit"
      @edit="handleNetboomEdit"
      @confirm="handleNetboomConfirm"
      @cancel="handleNetboomCancel"
    >
      <el-form
        :model="netboomInfo"
        label-width="100px"
        style="width: 600px"
      >
        <el-table
          :data="netboomInfo.data"
          border
          :show-summary="true"
          sum-text="评价总人数"
        >
          <el-table-column prop="rowTitle" />
          <el-table-column
            prop="preValue"
            label="预设值"
            width="230px"
          >
            <template #default="{row}">
              <template v-if="netboomInfo.isEdit">
                <el-input-number
                  v-model="row.preValue "
                  :min="0"
                  :step="1"
                  step-strictly
                  @change="preValueUpdate(row)"
                />
              </template>
              <template v-else>
                {{ row.preValue }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="trueValue"
            label="真实值"
          />
          <el-table-column
            prop="total"
            label="合计值"
          />
        </el-table>
      </el-form>
      <div class="result-count">
        <span class="title">
          最终评分:
        </span>
        <span class="value">
          {{ finallyScore }}
        </span>
      </div>
    </EditSection>

    <EditSection
      v-loading="thirdPartyInfo.loading"
      title="第三方评分"
      :is-edit="thirdPartyInfo.isEdit"
      @edit="handleThirdPartyEdit"
      @confirm="handleThirdPartyConfirm"
      @cancel="handleThirdPartyCancel"
    >
      <el-form
        :model="thirdPartyInfo.data"
        label-width="120px"
        style="width: 450px"
      >
        <el-form-item label="Metacritic评分：">
          <template v-if="thirdPartyInfo.isEdit">
            <el-input-number
              v-model="thirdPartyInfo.data.metric"
              :min="0"
              :max="100"
            />
          </template>
          <template v-else>
            <span>{{ gameDetailBasic.metric || '/' }}</span>
          </template>
        </el-form-item>
      </el-form>
    </EditSection>
  </div>
</template>

<script>
import EditSection from '@/views/game-library/components/EditSection.vue'
import { mapGetters, mapMutations } from 'vuex'
import { cloneDeep } from 'lodash'
import {
  editAppraise,
  netboomScore,
  udpateNetboomScore,
  addTask
} from '@/api/gameList.js'

const netboomInfoData = [
  { rowTitle: '1星评价数', preValue: 0, trueValue: 0, total: 0 },
  { rowTitle: '2星评价数', preValue: 0, trueValue: 0, total: 0 },
  { rowTitle: '3星评价数', preValue: 0, trueValue: 0, total: 0 },
  { rowTitle: '4星评价数', preValue: 0, trueValue: 0, total: 0 },
  { rowTitle: '5星评价数', preValue: 0, trueValue: 0, total: 0 }
]
export default {
  components: { EditSection },
  data () {
    return {
      updateLoading: false,
      steamInfo: {
        data: {},
        isEdit: false,
        loading: false
      },
      thirdPartyInfo: {
        data: {},
        isEdit: false,
        loading: false
      },
      netboomInfo: {
        soucreData: cloneDeep(netboomInfoData),
        data: netboomInfoData,
        isEdit: false,
        loading: false
      }
    }
  },
  computed: {
    ...mapGetters('gameList', ['gameDetailBasic']),
    finallyScore () {
      const weightList = [0, 4, 6, 8, 10]
      const result = this.netboomInfo.data.reduce((result, row, index) => {
        result.score += row.total * weightList[index]
        result.count += row.total
        return result
      }, { score: 0, count: 0 })
      const score = result.score / (2 * result.count)
      return !isNaN(score) ? score.toFixed(2) : '/'
    }
  },
  created () {
    this.queryNetboomScore()
  },
  methods: {
    ...mapMutations('gameList', ['updateGameDetailBasic']),
    // Steam评分模块
    handleSteamEdit () {
      this.steamInfo.data = cloneDeep(this.gameDetailBasic)
      this.steamInfo.isEdit = true
    },
    handleSteamConfirm () {
      this.steamInfo.loading = true
      const {
        basicId,
        appraiseTotalNum,
        appraiseGoodNum,
        appraiseBadNum
      } = this.steamInfo.data

      const data = {
        basicId,
        appraiseTotalNum,
        appraiseGoodNum,
        appraiseBadNum,
        metric: ''
      }
      editAppraise(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDetailBasic({
              appraiseTotalNum,
              appraiseGoodNum,
              appraiseBadNum
            })
            this.steamInfo.isEdit = false
          }
        })
        .finally(() => {
          this.steamInfo.loading = false
        })
    },
    handleSteamCancel () {
      this.steamInfo.isEdit = false
    },
    handleUpdateSteamInfo () {
      this.updateLoading = true
      addTask({
        platform: 'steam',
        sourceId: this.$route.query.sourceId,
        lang: ['en']
      })
        .then(res => {
          if (res.code === 200) {
            const h = this.$createElement
            this.$notify({
              title: '更新任务已建立',
              message: h(
                'i',
                { style: 'color: #409eff' },
                '页面不会自动更新, 稍等片刻后请自行刷新页面以查看更新情况'
              )
            })
          }
        })
        .finally(() => {
          this.updateLoading = false
        })
    },

    // Netboom 评分模块
    queryNetboomScore () {
      this.netboomInfo.loading = true
      netboomScore({ sourceId: this.$route.query.sourceId })
        .then(res => {
          if (res.code === 200) {
            this.netboomInfo.soucreData = this.transformScoreDataType(res.data)
            this.netboomInfo.data = cloneDeep(this.netboomInfo.soucreData)
          }
        })
        .finally(() => {
          this.netboomInfo.loading = false
        })
    },
    transformScoreDataType (data) {
      let result
      if (Array.isArray(data)) {
        result = data.reduce((obj, item, index) => {
          obj['defaultLevel' + (index + 1)] = item.preValue
          return obj
        }, {})
      } else {
        result = netboomInfoData.map((item, index) => {
          item.preValue = data['defaultLevel' + (index + 1)]
          item.trueValue = data['realLevel' + (index + 1)]
          item.total = item.preValue + item.trueValue
          return item
        })
      }
      return result
    },
    handleNetboomEdit () {
      this.netboomInfo.data = cloneDeep(this.netboomInfo.soucreData)
      this.netboomInfo.isEdit = true
    },
    handleNetboomConfirm () {
      const data = this.transformScoreDataType(this.netboomInfo.data)
      udpateNetboomScore({
        ...data,
        sourceId: this.$route.query.sourceId
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('更新成功')
          this.netboomInfo.soucreData = cloneDeep(this.netboomInfo.data)
          this.netboomInfo.isEdit = false
        }
      })
    },
    handleNetboomCancel () {
      this.netboomInfo.isEdit = false
      this.netboomInfo.data = cloneDeep(this.netboomInfo.soucreData)
    },
    computedTotal (row) {
      return row.preValue + row.trueValue
    },
    preValueUpdate (row) {
      row.total = this.computedTotal(row)
    },

    // 第三方评论模块
    handleThirdPartyEdit () {
      this.thirdPartyInfo.data = cloneDeep(this.gameDetailBasic)
      this.thirdPartyInfo.isEdit = true
    },
    handleThirdPartyConfirm () {
      this.thirdPartyInfo.loading = true

      const { basicId, metric } = this.thirdPartyInfo.data

      const data = {
        basicId,
        metric,
        appraiseTotalNum: '',
        appraiseGoodNum: '',
        appraiseBadNum: ''
      }
      editAppraise(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDetailBasic({ metric })
            this.thirdPartyInfo.isEdit = false
          }
        })
        .finally(() => {
          this.thirdPartyInfo.loading = false
        })
    },
    handleThirdPartyCancel () {
      this.thirdPartyInfo.isEdit = false
    }
  }
}
</script>

<style lang="less" scoped>
  .result-count {
    margin: 10px 0 10px 8px;
    color: #FD6101;
    .value {
      margin-left: 50px;
    }
  }
</style>
