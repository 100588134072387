var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('EditSection',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.typeTagsInfo.loading),expression:"typeTagsInfo.loading"}],attrs:{"title":"类型标签","is-edit":_vm.typeTagsInfo.isEdit},on:{"edit":_vm.handleTypeTagsEdit,"confirm":_vm.handleTypeTagsConfirm,"cancel":_vm.handleTypeTagsCancel},scopedSlots:_vm._u([(_vm.typeTagsInfo.isEdit)?{key:"title",fn:function(){return [_c('el-button',{staticClass:"clear-all",attrs:{"type":"text","title":"全部清除"},on:{"click":_vm.handleClearAllSelectTypeTags}},[_c('i',{staticClass:"el-icon-delete"})])]},proxy:true}:null],null,true)},[(_vm.typeTagsInfo.isEdit)?_c('div',{staticClass:"list-title"},[_c('span',[_vm._v(" 已选标签: ")])]):_vm._e(),(_vm.showTypeTagList)?_c('ul',{staticClass:"tags-list"},_vm._l(((_vm.typeTagsInfo.isEdit ? (_vm.typeTagsInfo.data || []): (_vm.gameDetailTags.typeTags || []))),function(item,index){return _c('li',{key:item.id,staticClass:"tags-wrapper",class:{
          edit: _vm.typeTagsInfo.isEdit
        },attrs:{"draggable":_vm.typeTagsInfo.isEdit ? true : false},on:{"dragover":function($event){$event.preventDefault();return _vm.handleDragover.apply(null, arguments)},"dragend":function($event){$event.preventDefault();},"dragstart":function($event){return _vm.handleDragstart(index, $event, 'typeTagsInfo')},"drop":function($event){$event.preventDefault();return _vm.handleDrop(index, $event, 'typeTagsInfo')}}},[(_vm.typeTagsInfo.isEdit)?_c('i',{staticClass:"el-icon-circle-close delete",attrs:{"title":"Delete"},on:{"click":function($event){return _vm.handleDeleteTypeTags(index)}}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.webTagNameZh || '/'))]),(_vm.typeTagsInfo.isEdit)?_c('div',{staticClass:"cover"},[_c('i',{staticClass:"el-icon-rank rank",attrs:{"title":"Move"}})]):_vm._e()])}),0):_c('div',{staticClass:"empty"},[_vm._v(" 没有相关数据 ")]),(_vm.typeTagsInfo.isEdit)?[_c('div',{staticClass:"list-title",staticStyle:{"margin-top":"50px"}},[_vm._v(" 可选标签: ")]),_c('div',{staticClass:"filter"},[_c('el-input',{attrs:{"placeholder":"本地数据筛选","clearable":""},model:{value:(_vm.typeTagsInfo.keywords),callback:function ($$v) {_vm.$set(_vm.typeTagsInfo, "keywords", $$v)},expression:"typeTagsInfo.keywords"}})],1),(_vm.typeTagFiltersList.length)?_c('ul',{staticClass:"tags-list candidate"},_vm._l((_vm.typeTagFiltersList),function(item,index){return _c('li',{key:item.id,staticClass:"tags-wrapper",class:{
            selected: _vm.isSelected(item, _vm.typeTagsInfo.data)
          },on:{"click":function($event){return _vm.handleAddTypeTags(index)}}},[_c('span',[_vm._v(_vm._s(item.webTagNameZh || '/'))]),_c('i',{staticClass:"el-icon-circle-check add",attrs:{"title":"All"}})])}),0):_c('div',{staticClass:"empty"},[_vm._v(" 没有相关数据 ")])]:_vm._e()],2),_c('EditSection',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.descTagsInfo.loading),expression:"descTagsInfo.loading"}],attrs:{"title":"描述标签","is-edit":_vm.descTagsInfo.isEdit},on:{"edit":_vm.handleDescTagsEdit,"confirm":_vm.handleDescTagsConfirm,"cancel":_vm.handleDescTagsCancel},scopedSlots:_vm._u([(_vm.descTagsInfo.isEdit)?{key:"title",fn:function(){return [_c('el-button',{staticClass:"clear-all",attrs:{"type":"text","title":"全部清除"},on:{"click":_vm.handleClearAllSelectDescTags}},[_c('i',{staticClass:"el-icon-delete"})])]},proxy:true}:null],null,true)},[(_vm.descTagsInfo.isEdit)?_c('div',{staticClass:"list-title"},[_vm._v(" 已选标签: ")]):_vm._e(),(_vm.showDescTagList)?_c('ul',{staticClass:"tags-list"},_vm._l(((_vm.descTagsInfo.isEdit ? (_vm.descTagsInfo.data || []): (_vm.gameDetailTags.descTags || []))),function(item,index){return _c('li',{key:item.id,staticClass:"tags-wrapper",class:{
          edit: _vm.descTagsInfo.isEdit
        },attrs:{"draggable":_vm.descTagsInfo.isEdit ? true : false},on:{"dragover":function($event){$event.preventDefault();return _vm.handleDragover.apply(null, arguments)},"dragend":function($event){$event.preventDefault();},"dragstart":function($event){return _vm.handleDragstart(index, $event, 'descTagsInfo')},"drop":function($event){$event.preventDefault();return _vm.handleDrop(index, $event, 'descTagsInfo')}}},[(_vm.descTagsInfo.isEdit)?_c('i',{staticClass:"el-icon-circle-close delete",attrs:{"title":"Delete"},on:{"click":function($event){return _vm.handleDeleteDescTags(index)}}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.webTagNameZh || '/'))]),(_vm.descTagsInfo.isEdit)?_c('div',{staticClass:"cover"},[_c('i',{staticClass:"el-icon-rank rank",attrs:{"title":"Move"}})]):_vm._e()])}),0):_c('div',{staticClass:"empty"},[_vm._v(" 没有相关数据 ")]),(_vm.descTagsInfo.isEdit)?[_c('div',{staticClass:"list-title",staticStyle:{"margin-top":"50px"}},[_c('span',[_vm._v("可选标签:")])]),_c('div',{staticClass:"filter"},[_c('el-input',{attrs:{"placeholder":"本地数据筛选","clearable":""},model:{value:(_vm.descTagsInfo.keywords),callback:function ($$v) {_vm.$set(_vm.descTagsInfo, "keywords", $$v)},expression:"descTagsInfo.keywords"}})],1),_c('ul',{staticClass:"tags-item-ul"},_vm._l((_vm.descTagFiltersList),function(item,index){return _c('li',{key:index,staticClass:"tags-item-list"},[_c('div',{staticClass:"item-name"},[_vm._v(" "+_vm._s(item.groupName)+" ")]),_c('ul',{staticClass:"tags-list candidate"},_vm._l((item.tagList || []),function(it){return _c('li',{key:it.tagId,staticClass:"tags-wrapper",class:{
                selected: _vm.isSelected(it, _vm.descTagsInfo.data)
              },on:{"click":function($event){return _vm.handleAddDescTags(it)}}},[_c('span',[_vm._v(_vm._s(it.webTagNameZh || '/'))]),_c('i',{staticClass:"el-icon-circle-check add",attrs:{"title":"All"}})])}),0)])}),0)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }