<template>
  <section>
    <EditSection
      title="游戏特性评价"
      :show-edit="false"
    >
      <el-table
        v-loading="listInfo.loading"
        :data="listInfo.list"
        border
        style="width: 600px"
      >
        <el-table-column
          label="游戏特性"
          prop="name"
        />
        <el-table-column
          label="赞"
          prop="up"
        />
        <el-table-column
          label="踩"
          prop="down"
        />
      </el-table>
    </EditSection>
  </section>
</template>

<script>
import EditSection from '@/views/game-library/components/EditSection.vue'
import {
  netboomComment
} from '@/api/gameList.js'
export default {
  name: 'NetboomComment',
  components: { EditSection },
  data () {
    return {
      listInfo: {
        list: [],
        loading: true
      }
    }
  },
  created () {
    this.queryData()
  },
  methods: {
    queryData () {
      this.listInfo.loading = true
      netboomComment({
        sourceId: this.$route.query.sourceId
      })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    }
  }

}
</script>

<style>

</style>
