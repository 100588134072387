<template>
  <section>
    <EditSection
      title="Steam艺术品"
      :show-edit="false"
    >
      <div class="page-description">
        已上架艺术品：{{ listInfo.shelvesnumber }}个
      </div>
      <BHeader style="margin-bottom: 0px;">
        <el-form
          ref="form"
          :inline="true"
          :model="formData"
          @submit.native.prevent
        >
          <el-form-item prop="keyWord">
            <el-select
              v-model="formData.mstatus"
              placeholder="请选择状态"
              clearable
            >
              <el-option
                v-for="(item, index) in orderStatusList"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
            <el-select
              v-model="formData.mediaType"
              style="margin-left:20px"
              placeholder="请选择图片类型"
              clearable
            >
              <el-option
                v-for="(item, index) in imgStatusList"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleSearch"
            >
              搜索
            </el-button>
          </el-form-item>
        </el-form>
      </BHeader>

      <el-table
        v-loading="listInfo.loading"
        :data="listInfo.list"
        stripe
        style="width: 100%"
      >
        <el-table-column
          label="ID"
          prop="id"
        />
        <el-table-column
          label="爬取URL"
          prop="link"
        >
          <template #default="{row}">
            <div
              v-clipboard:copy="row.link"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              class="copy-btn"
            >
              {{ row.link }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="爬取时间"
          prop="createTime"
          width="180"
        />
        <el-table-column
          label="用户昵称"
          prop="nickName"
        />
        <el-table-column
          label="用户头像"
          width="120"
        >
          <template #default="{row}">
            <div
              class="video-body"
              style="width: 80px; height: 60px"
            >
              <el-image
                style="width: 100%;height: 100%;"
                :src="row.avatar"
                fit="cover"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="类型"
          prop="mediaType"
        />
        <el-table-column
          prop="sfrom"
          label="来源"
        >
          <template #default="{row}">
            <template>
              <span v-if="row.sfrom == 2">
                截图
              </span>
              <span v-if="row.sfrom == 3">
                视频
              </span>
              <span v-if="row.sfrom == 1">
                艺术品
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="标题"
          prop="title"
        />
        <el-table-column
          label="内容"
          width="140"
        >
          <template #default="{row}">
            <div
              v-if="row.mediaType == 'video'"
              class="video-body"
              style="width: 120px; height: 60px"
            >
              <el-image
                style="width: 100%"
                :src="row.mediaCover"
              >
                <div
                  slot="placeholder"
                  class="image-slot"
                >
                  <i class="el-icon-loading" />
                </div>
                <div
                  slot="error"
                  class="image-slot"
                >
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
              <div class="cover">
                <i
                  class="el-icon-video-play video-play"
                  title="Play"
                  @click="handlePlayVideo(row)"
                />
              </div>
            </div>
            <div
              v-else
              class="video-body"
              style="width: 120px; height: 60px"
              @click="handlePlayImg(row.mediaUrl)"
            >
              <el-image
                style="width: 100%;height: 100%;"
                :src="row.mediaUrl"
                fit="cover"
              >
                <div
                  slot="placeholder"
                  class="image-slot"
                >
                  <i class="el-icon-loading" />
                </div>
                <div
                  slot="error"
                  class="image-slot"
                >
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
              <div class="cover">
                <i
                  class="el-icon-orange video-play"
                  title="Play"
                />
              </div>
            </div>
            <!-- <a
              style="text-decoration: underline;color: #8229e5;"
              @click="handleCreateTag(row)"
            >
              点击查看
            </a> -->
          </template>
        </el-table-column>
        <el-table-column
          label="首帧图"
          width="140"
        >
          <template #default="{row}">
            <div
              v-if="row.mediaCover"
              class="video-body"
              style="width: 120px; height: 60px"
              @click="handlePlayImg(row.mediaCover)"
            >
              <el-image
                style="width: 100%;height: 100%;"
                :src="row.mediaCover"
                fit="cover"
              >
                <div
                  slot="placeholder"
                  class="image-slot"
                >
                  <i class="el-icon-loading" />
                </div>
                <div
                  slot="error"
                  class="image-slot"
                >
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
              <div class="cover">
                <i
                  class="el-icon-orange video-play"
                  title="Play"
                />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="点赞的人数"
          prop="likeUserNum"
        />
        <el-table-column
          label="评论人数"
          prop="commentUserNum"
        />
        <el-table-column
          label="发布时间"
          prop="publishTime"
          width="180"
        />
        <el-table-column
          prop="mstatus"
          label="状态"
        >
          <template #default="{row}">
            <template>
              <span v-if="row.mstatus == 2">
                上架
              </span>
              <span v-if="row.mstatus == 3">
                下架
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="200"
          fixed="right"
        >
          <template #default="{row}">
            <el-button
              :type="row.mstatus === 2 ? 'primary': 'danger'"
              :loading="row.loading"
              @click="handleToggleState(row,row.mstatus)"
            >
              {{ row.mstatus === 3 ? '上架' : '下架' }}
            </el-button>
            <el-button
              :loading="row.loading2"
              @click="handleTopping(row)"
            >
              置顶
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="listInfo.total"
        :page.sync="formData.pageNum"
        :limit.sync="formData.pageSize"
        @pagination="queryDataList"
      />
      <el-dialog
        :visible.sync="playImg"
        :center="true"
        top="80px"
        width="1000px"
      >
        <el-image
          style="width: 100%; height: 500px;"
          :src="currentRows"
          fit="cover"
        >
          <div
            slot="placeholder"
            class="image-slot image-slot-loading"
          >
            loading<span class="dot">...</span>
          </div>
          <div
            slot="error"
            class="image-slot image-slot-error"
          >
            <div class="error" />
          </div>
        </el-image>
        <div
          slot="pagination"
          class="swiper-pagination"
        />
        <div
          slot="button-prev"
          class="swiper-button-prev"
        />
        <div
          slot="button-next"
          class="swiper-button-next"
        />
      </el-dialog>
      <el-dialog
        :visible.sync="playVideo"
        :center="true"
        top="80px"
        width="90%"
      >
        <div class="play-wrapper">
          <video
            v-if="playVideo"
            width="100%"
            height="100%"
            controls
          >
            <source src="https://cdn.bifrost.com/dev/videos/33100d499439b7df6422cfe7a7a5c8e7.mp4">
            Your browser does not support video tags
          </video>
        </div>
      </el-dialog>
      <el-dialog
        title="评论内容"
        :visible.sync="showDialog"
        width="600px"
        @close="handleDialogClose"
      >
        <div>{{ subForm.tagId }}</div>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="showDialog = false">取 消</el-button>
          <el-button @click="showDialog = false">确 定</el-button>
        </span>
      </el-dialog>
    </EditSection>
  </section>
</template>

<script>
import {
  steamArtistList,
  updateArtWorkStatus,
  updateArtWorkSort
} from '@/api/gameList.js'
import EditSection from '@/views/game-library/components/EditSection.vue'
export default {
  name: 'SteamArtist',
  components: { EditSection },
  data () {
    return {
      playVideo: false,
      playImg: false,
      currentRows: '',
      currentVidoeURL: '',
      imgStatusList: [
        { name: '视频', value: 'video' },
        { name: '图片', value: 'image' }
      ],
      orderStatusList: [
        { name: '上架', value: 2 },
        { name: '下架', value: 3 }
      ],
      formData: {
        pageNum: 1,
        mediaType: 'image',
        mstatus: 2,
        sourceId: '',
        pageSize: 20
      },
      listInfo: {
        shelvesnumber: 0,
        list: [],
        total: 0,
        loading: false
      },
      showDialog: false,
      subForm: {}
    }
  },
  created () {
    this.formData.sourceId = this.$route.query.sourceId
    this.handleSearch()
  },
  methods: {
    onCopy (e) {
      this.$message.success('内容已复制到剪切板！')
    },
    // 复制失败时的回调函数
    onError (e) {
      this.$message.error('抱歉，复制失败！')
    },
    handleTopping (row) {
      this.$set(row, 'loading2', true)
      updateArtWorkSort({ id: row.id })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('置顶成功')
            this.queryDataList()
          }
        })
        .finally(() => {
          this.$set(row, 'loading2', false)
        })
    },
    handleToggleState (row, mstatus) {
      if (mstatus === 2) {
        mstatus = 3
      } else if (mstatus === 3) {
        mstatus = 2
      }
      this.$set(row, 'loading', true)
      updateArtWorkStatus({ id: row.id, mstatus: mstatus })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('更新成功')
            this.queryDataList()
          }
        })
        .finally(() => {
          this.$set(row, 'loading', false)
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      this.listInfo.loading = true
      steamArtistList(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
            this.listInfo.shelvesnumber = res.data.onlineCount || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleCreateTag (obj) {
      this.showDialog = true
      this.subForm = obj
    },
    handleDialogClose () {
      this.subForm = {}
    },
    handlePlayVideo (item) {
      const arr = item.mediaUrl.split('.')
      if (arr[arr.length - 1] === 'mp4') {
        this.playVideo = true
        this.currentVidoeURL = item.mediaUrl
      } else {
        window.open(item.mediaUrl, '_blank')
      }
    },
    handlePlayImg (row) {
      this.playImg = true
      this.currentRows = row
    }
  }
}
</script>

<style socped lang="less">
 .video-body {
        width: 100%;
        background-color: #F5F7FA;
        border-radius: 8px;
        background-size: cover;
        overflow: hidden;
        position: relative;
        &:hover {
          .cover {
            display: flex;
          }
        }
        .image-slot {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
        .cover {
          display: none;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border-radius: 8px;

          .video-play {
            color: white;
            font-size: 28px;
            cursor: pointer;
          }
        }
      }
.page-description {
  font-size: 14px;
  color: #aaa;
  margin: 30px 0;
}
.header {
  font-size: 16px;
  padding: 0 0 20px 20px;
}
.copy-btn {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
