<template>
  <div>
    <EditSection
      v-loading="typeTagsInfo.loading"
      title="类型标签"
      :is-edit="typeTagsInfo.isEdit"
      @edit="handleTypeTagsEdit"
      @confirm="handleTypeTagsConfirm"
      @cancel="handleTypeTagsCancel"
    >
      <template
        v-if="typeTagsInfo.isEdit"
        #title
      >
        <el-button
          class="clear-all"
          type="text"
          title="全部清除"
          @click="handleClearAllSelectTypeTags"
        >
          <i class="el-icon-delete" />
        </el-button>
      </template>
      <div
        v-if="typeTagsInfo.isEdit"
        class="list-title"
      >
        <span>
          已选标签:
        </span>
      </div>
      <ul
        v-if="showTypeTagList"
        class="tags-list"
      >
        <li
          v-for="(item, index) in (typeTagsInfo.isEdit ? (typeTagsInfo.data || []): (gameDetailTags.typeTags || []))"
          :key="item.id"
          class="tags-wrapper"
          :class="{
            edit: typeTagsInfo.isEdit
          }"
          :draggable="typeTagsInfo.isEdit ? true : false"
          @dragover.prevent="handleDragover"
          @dragend.prevent
          @dragstart="handleDragstart(index, $event, 'typeTagsInfo')"
          @drop.prevent="handleDrop(index, $event, 'typeTagsInfo')"
        >
          <i
            v-if="typeTagsInfo.isEdit"
            title="Delete"
            class="el-icon-circle-close delete"
            @click="handleDeleteTypeTags(index)"
          />
          <span>{{ item.webTagNameZh || '/' }}</span>
          <div
            v-if="typeTagsInfo.isEdit"
            class="cover"
          >
            <i
              class="el-icon-rank rank"
              title="Move"
            />
          </div>
        </li>
      </ul>
      <div
        v-else
        class="empty"
      >
        没有相关数据
      </div>
      <template v-if="typeTagsInfo.isEdit">
        <div
          class="list-title"
          style="margin-top: 50px;"
        >
          可选标签:
        </div>
        <div class="filter">
          <el-input
            v-model="typeTagsInfo.keywords"
            placeholder="本地数据筛选"
            clearable
          />
        </div>
        <ul
          v-if="typeTagFiltersList.length"
          class="tags-list candidate"
        >
          <li
            v-for="(item, index) in typeTagFiltersList"
            :key="item.id"
            class="tags-wrapper"
            :class="{
              selected: isSelected(item, typeTagsInfo.data)
            }"
            @click="handleAddTypeTags(index)"
          >
            <span>{{ item.webTagNameZh || '/' }}</span>
            <i
              title="All"
              class="el-icon-circle-check add"
            />
          </li>
        </ul>
        <div
          v-else
          class="empty"
        >
          没有相关数据
        </div>
      </template>
    </EditSection>

    <EditSection
      v-loading="descTagsInfo.loading"
      title="描述标签"
      :is-edit="descTagsInfo.isEdit"
      @edit="handleDescTagsEdit"
      @confirm="handleDescTagsConfirm"
      @cancel="handleDescTagsCancel"
    >
      <template
        v-if="descTagsInfo.isEdit"
        #title
      >
        <el-button
          class="clear-all"
          type="text"
          title="全部清除"
          @click="handleClearAllSelectDescTags"
        >
          <i class="el-icon-delete" />
        </el-button>
      </template>
      <div
        v-if="descTagsInfo.isEdit"
        class="list-title"
      >
        已选标签:
      </div>
      <ul
        v-if="showDescTagList"
        class="tags-list"
      >
        <li
          v-for="(item, index) in (descTagsInfo.isEdit ? (descTagsInfo.data || []): (gameDetailTags.descTags || []))"
          :key="item.id"
          class="tags-wrapper"
          :class="{
            edit: descTagsInfo.isEdit
          }"
          :draggable="descTagsInfo.isEdit ? true : false"
          @dragover.prevent="handleDragover"
          @dragend.prevent
          @dragstart="handleDragstart(index, $event, 'descTagsInfo')"
          @drop.prevent="handleDrop(index, $event, 'descTagsInfo')"
        >
          <i
            v-if="descTagsInfo.isEdit"
            title="Delete"
            class="el-icon-circle-close delete"
            @click="handleDeleteDescTags(index)"
          />
          <span>{{ item.webTagNameZh || '/' }}</span>
          <div
            v-if="descTagsInfo.isEdit"
            class="cover"
          >
            <i
              class="el-icon-rank rank"
              title="Move"
            />
          </div>
        </li>
      </ul>

      <div
        v-else
        class="empty"
      >
        没有相关数据
      </div>
      <template v-if="descTagsInfo.isEdit">
        <div
          class="list-title"
          style="margin-top: 50px;"
        >
          <span>可选标签:</span>
        </div>
        <div class="filter">
          <el-input
            v-model="descTagsInfo.keywords"
            placeholder="本地数据筛选"
            clearable
          />
        </div>
        <ul class="tags-item-ul">
          <li
            v-for="(item, index) in descTagFiltersList"
            :key="index"
            class="tags-item-list"
          >
            <div class="item-name">
              {{ item.groupName }}
            </div>

            <ul class="tags-list candidate">
              <li
                v-for="it in item.tagList || []"
                :key="it.tagId"
                class="tags-wrapper"
                :class="{
                  selected: isSelected(it, descTagsInfo.data)
                }"
                @click="handleAddDescTags(it)"
              >
                <span>{{ it.webTagNameZh || '/' }}</span>
                <i
                  title="All"
                  class="el-icon-circle-check add"
                />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </EditSection>
  </div>
</template>

<script>
import EditSection from '@/views/game-library/components/EditSection.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import { editGameTagsInfo, getHotTags } from '@/api/gameList.js'
export default {
  name: 'TagManagement',
  components: { EditSection },
  data () {
    return {
      typeTagsInfo: {
        data: [],
        isEdit: false,
        loading: false,
        keywords: ''
      },
      descTagsInfo: {
        data: [],
        isEdit: false,
        loading: false,
        keywords: ''
      }
    }
  },
  computed: {
    ...mapGetters('gameList', ['gameDetailTags', 'gameDetailBasic']),
    ...mapState('gameList', ['typeTagList', 'descTagList']),
    showTypeTagList () {
      if (this.typeTagsInfo.isEdit) {
        return this.typeTagsInfo.data && this.typeTagsInfo.data.length
      } else {
        return this.gameDetailTags.typeTags && this.gameDetailTags.typeTags.length
      }
    },
    showDescTagList () {
      if (this.descTagsInfo.isEdit) {
        return this.descTagsInfo.data && this.descTagsInfo.data.length
      } else {
        return this.gameDetailTags.descTags && this.gameDetailTags.descTags.length
      }
    },
    typeTagFiltersList () {
      return this.typeTagList.filter(item => {
        return item.webTagNameZh.includes(this.typeTagsInfo.keywords)
      })
    },
    descTagFiltersList () {
      return this.descTagList.map(item => {
        const tagList = item.tagList.filter(it => {
          return it.webTagNameZh.includes(this.descTagsInfo.keywords)
        })
        return {
          ...item,
          tagList
        }
      })
    }
  },
  created () {
    this.queryHotTagsList()
  },
  methods: {
    ...mapMutations('gameList', [
      'updateGameDetailTypeTags',
      'updateGameDetailDescTags',
      'updateTypeTagList',
      'updateDescTagList'
    ]),
    isSelected (item, list) {
      return !!(list || []).filter(it => it.tagId === item.tagId).length
    },
    queryHotTagsList () {
      // int64 from = 1 ;//1: steam,2: 运营自己加的
      getHotTags({})
        .then(res => {
          if (res.code === 200) {
            this.updateTypeTagList(res.data.typeTags || {})
            this.updateDescTagList(res.data.descTags || {})
          }
        })
    },
    handleTypeTagsEdit () {
      this.typeTagsInfo.data = cloneDeep(this.gameDetailTags.typeTags) || []
      this.typeTagsInfo.isEdit = true
      this.typeTagsInfo.keywords = ''
    },
    handleTypeTagsConfirm () {
      if (!this.typeTagsInfo.data.length) {
        this.$message.error('至少选择一个类型标签')
        return false
      }
      this.typeTagsInfo.loading = true

      const basicId = this.gameDetailBasic.basicId
      const tagGroup = 'typeTag'
      const tagIds = (this.typeTagsInfo.data || []).map(item => item.tagId)

      const data = { basicId, tagIds, tagGroup }
      editGameTagsInfo(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDetailTypeTags(this.typeTagsInfo.data)
            this.typeTagsInfo.isEdit = false
          }
        })
        .finally(() => {
          this.typeTagsInfo.loading = false
        })
    },
    handleTypeTagsCancel () {
      this.typeTagsInfo.isEdit = false
    },
    handleDeleteTypeTags (index) {
      this.typeTagsInfo.data.splice(index, 1)
    },
    handleClearAllSelectTypeTags () {
      this.typeTagsInfo.data = []
    },
    handleAddTypeTags (index) {
      const selection = this.typeTagFiltersList[index]

      const findedIndex = (this.typeTagsInfo.data).findIndex(item => {
        return item.tagId === selection.tagId
      })
      if (findedIndex > -1) {
        this.handleDeleteTypeTags(findedIndex)
      } else {
        this.typeTagsInfo.data.push(this.typeTagFiltersList[index])
      }
    },

    handleDescTagsEdit () {
      this.descTagsInfo.data = cloneDeep(this.gameDetailTags.descTags) || []
      this.descTagsInfo.isEdit = true
      this.descTagsInfo.keywords = ''
    },
    handleDescTagsConfirm () {
      this.descTagsInfo.loading = true

      const basicId = this.gameDetailBasic.basicId
      const tagGroup = 'descTag'
      const tagIds = (this.descTagsInfo.data || []).map(item => item.tagId)

      const data = { basicId, tagIds, tagGroup }
      editGameTagsInfo(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDetailDescTags(this.descTagsInfo.data)
            this.descTagsInfo.isEdit = false
          }
        })
        .finally(() => {
          this.descTagsInfo.loading = false
        })
    },
    handleDescTagsCancel () {
      this.descTagsInfo.isEdit = false
    },
    handleDeleteDescTags (index) {
      this.descTagsInfo.data.splice(index, 1)
    },
    handleClearAllSelectDescTags () {
      this.descTagsInfo.data = []
    },
    handleAddDescTags (it) {
      const findedIndex = this.descTagsInfo.data.findIndex(item => {
        return item.tagId === it.tagId
      })
      if (findedIndex > -1) {
        this.handleDeleteDescTags(findedIndex)
      } else {
        this.descTagsInfo.data.push(it)
      }
    },

    handleDragover (event) {
      event.dataTransfer.dropEffect = 'link'
    },
    handleDragstart (index, event, field) {
      event.dataTransfer.setData('text/plain', JSON.stringify({ sourceIndex: index, field }))
      event.dataTransfer.dropEffect = 'link'
    },
    handleDrop (index, event, field) {
      const data = event.dataTransfer.getData('text/plain')
      try {
        const sourceData = JSON.parse(data)
        if (!sourceData.field ||
         index === sourceData.sourceIndex ||
          sourceData.field !== field
        ) return false
        // 暂存activeIndex 所在ID
        const temp = this[field].data.splice(sourceData.sourceIndex, 1)
        this[field].data.splice(index, 0, temp[0])
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .clear-all {
      margin-left: 10px;
    }
  .list-title {
    font-size: 14px;
    color: #666;
    padding-left: 44px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  .filter {
    padding-left: 44px;
    margin-top: 30px;
  }
  .collapse-wrapper {
    padding: 30px 0px 0 48px;
  }
  .empty {
    font-size: 14px;
    text-align: center;
    color: #aaa;
    margin-top: 50px;
  }
  .tags-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    .tags-wrapper {
      padding: 6px 30px;
      margin: 6px;
      position: relative;
      background: #BEE7E9;
      color: #333;
      border-radius: 4px;
      .delete {
        position: absolute;
        color: white;
        top: 4px;
        right: 2px;
        font-size: 20px;
        z-index: 100;
        cursor: pointer;
        &:hover {
          color: #F4606C;
        }
      }
      &.edit {
        &:hover {
          .cover {
            display: flex;
          }
        }
      }
      &.custom {
        background: #ffdc7e;
      }
      .cover {
        display: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        color: white;
        border-radius: 4px;
        .rank {
          color: white;
          font-size: 18px;
          cursor: grab;
        }
      }
    }

    &.candidate {
      .tags-wrapper {
        background: #e5e5e5;
        color: #666;
        cursor: pointer;
        &.selected {
          background: #BEE7E9;
        }
        .add {
          position: absolute;
          color: white;
          top: 4px;
          right: 2px;
          font-size: 20px;
          z-index: 100;
        }
      }
    }
  }
  .tags-item-ul {
    list-style: none;
    >li {
      display: flex;
      min-height: 40px;
      .item-name {
        width: 100px;
        flex-shrink: 0;
        padding-top: 10px;
        text-align: right;
      }
      .tags-list {
        padding-left: 10px;
      }
    }
  }
</style>
