<template>
  <div class="EditSection">
    <header>
      <div class="title">
        {{ title }}
        <i
          v-if="showEdit"
          class="fa fa-pencil-square-o icon"
          :class="{active: isEdit}"
          @click="handleEdit"
        />
        <slot name="title" />
      </div>
      <div
        v-if="isEdit"
        class="ctrl"
      >
        <el-button
          type="primary"
          class="save-button"
          plain
          @click="handleConfirm"
        >
          保存修改
        </el-button>
        <el-button
          plain
          class="cancel-button"
          @click="handleCancel"
        >
          取消修改
        </el-button>
      </div>
    </header>
    <section class="content">
      <slot>
        Content...
      </slot>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Unknown'
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleEdit () {
      if (this.isEdit) return
      this.$emit('edit')
    },
    handleConfirm () {
      this.$emit('confirm')
    },
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="less">
  .EditSection {
    margin-bottom: 100px;
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      background: white;;
      z-index: 1800;
      height: 50px;
      border-bottom: 1px solid #dcdfe6;
      .title {
        height: 36px;
        line-height: 36px;
        padding-left: 20px;
        border-left: 2px solid #999;
        .icon {
          margin-left: 20px;
          cursor: pointer;
          &.active {
            color: @primary;
          }
        }
      }
      .save-button {
        margin-left: 50px;
      }
      .cancel-button {
        margin-left: 20px;
      }
    }
    section {
      &.content {
        margin-top: 20px;
      }
    }
  }
</style>
