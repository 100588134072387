<template>
  <Layout class="GameLibraryDetail">
    <header>
      <div class="left">
        <el-image
          class="game-cover"
          :src="gameDetailMedias.headImage.urls"
          fit="cover"
        />
        <div class="game-overview">
          <div
            v-loading="basicForm.loading"
            class="game-name"
          >
            <span v-if="!isEdit"> {{ gameDetailBasic.gameName || '----------------' }}</span>
            <template v-else>
              <el-form
                ref="form"
                :model="basicForm"
                :rules="basicForm.rules"
                inline
                size="mini"
              >
                <el-form-item prop="gameName">
                  <el-input
                    v-model="basicForm.gameName"
                    placeholder="English name"
                    style="width: 280px"
                  />
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    class="save-button"
                    plain
                    @click="handleConfirmEdit"
                  >
                    保存
                  </el-button>
                  <el-button
                    plain
                    class="cancel-button"
                    @click="handleCancelEdit"
                  >
                    取消
                  </el-button>
                </el-form-item>
              </el-form>
            </template>

            <i
              v-if="!isEdit"
              class="fa fa-pencil-square-o icon"
              @click="handleEdit"
            />
          </div>
          <div class="game-id">
            ID: <span class="value">{{ gameDetailBasic.sourceId || '----' }}</span>
          </div>
          <div class="game-source-page">
            爬取源链接: <span class="value">{{ gameDetailBasic.urls || '------------------------------------------' }}</span>
          </div>
        </div>
      </div>
      <div class="right">
        <el-button @click="synchronizationWithOnline">
          <i class="el-icon-refresh" />
          同步至线上
        </el-button>
      </div>
    </header>
    <section class="tabs">
      <el-tabs
        v-model="activeName"
        :before-leave="handleBeforeLeave"
        @tab-click="handleTabChange"
      >
        <el-tab-pane
          label="基础信息"
          name="basic"
        />
        <el-tab-pane
          label="图像信息"
          name="images"
        />
        <el-tab-pane
          label="视频信息"
          name="videos"
        />
        <el-tab-pane
          label="标签信息"
          name="tags"
        />
        <el-tab-pane
          label="描述信息(多语言)"
          name="docs"
        />
        <el-tab-pane
          label="评分"
          name="score"
        />
        <el-tab-pane
          label="steam评论"
          name="steamComment"
        />
        <el-tab-pane
          label="steam艺术品"
          name="steamArtist"
        />
        <el-tab-pane
          label="netboom评论"
          name="netboomComment"
        />
        <el-tab-pane
          label="推荐商品"
          name="recommendGoods"
        />
      </el-tabs>
    </section>
    <section
      v-loading="gameDetailLoading"
      class="content"
    >
      <keep-alive exclude="GameDocs">
        <component :is="activeName" />
      </keep-alive>
    </section>
  </Layout>
</template>

<script>
import basic from '@/views/game-library/components/pageComp/basic.vue'
import score from '@/views/game-library/components/pageComp/score.vue'
import docs from '@/views/game-library/components/pageComp/docs.vue'
import images from '@/views/game-library/components/pageComp/images.vue'
import tags from '@/views/game-library/components/pageComp/tags.vue'
import videos from '@/views/game-library/components/pageComp/videos.vue'
import steamComment from '@/views/game-library/components/pageComp/steamComment.vue'
import steamArtist from '@/views/game-library/components/pageComp/steamArtist.vue'
import netboomComment from '@/views/game-library/components/pageComp/netboomComment.vue'
import recommendGoods from '@/views/game-library/components/pageComp/recommendGoods.vue'
import { mapGetters, mapMutations } from 'vuex'
import { getGameDetail, editGameName, synchronizeSpiderData } from '@/api/gameList.js'

export default {
  name: 'GameLibraryDetail',
  components: {
    basic, score, docs, images, tags, videos, steamComment, steamArtist, netboomComment, recommendGoods
  },
  data () {
    return {
      gameDetailLoading: false,
      activeName: '',
      isEdit: false,
      basicForm: {
        basicId: '',
        gameName: '',
        loading: false,
        rules: {
          gameName: [
            { required: true, message: '游戏名称不能为空' }
          ]
        }
      },
      fullLoading: {}
    }
  },
  computed: {
    ...mapGetters('gameList', ['gameDetailBasic', 'gameDetailMedias'])
  },
  created () {
    this.activeName = this.$route.params.activeName || 'basic'
    const { platform, sourceId } = this.$route.query
    this.queryGameDetail({ platform, sourceId })
    this.activeName = location.hash ? location.hash.slice(1) : 'basic'
  },
  beforeRouteUpdate (to, from, next) {
    this.activeName = location.hash.slice(1)
    next()
  },
  methods: {
    ...mapMutations('gameList', ['updateGameDetail', 'updateGameDetailBasic']),
    handleTabChange (instance) {
      // location.hash = instance.name
      const { origin, pathname, search } = location
      location.replace(origin + pathname + search + '#' + instance.name)
      // this.$router.push({
      //   name: this.$route.name,
      //   params: {
      //     activeName: instance.name
      //   },
      //   query: this.$route.query
      // })
    },
    handleBeforeLeave (activeName, oldActiveName) {
    },
    queryGameDetail ({ platform, sourceId }) {
      this.gameDetailLoading = true
      getGameDetail({ platform, sourceId })
        .then(res => {
          if (res.code === 200) {
            this.updateGameDetail(res.data)
          }
        })
        .finally(() => {
          this.gameDetailLoading = false
        })
    },
    handleEdit () {
      const { basicId, gameName } = this.gameDetailBasic
      Object.assign(
        this.basicForm,
        { basicId, gameName }
      )
      this.isEdit = true
    },
    handleConfirmEdit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { basicId, gameName } = this.basicForm
          this.basicForm.loading = true
          editGameName({ basicId, gameName })
            .then(res => {
              if (res.code === 200) {
                this.updateGameDetailBasic({ gameName })
                this.isEdit = false
              }
            })
            .finally(() => {
              this.basicForm.loading = false
            })
        }
      })
    },
    handleCancelEdit () {
      this.isEdit = false
    },

    synchronizationWithOnline () {
      this.fullLoading = this.$loading({
        lock: true,
        text: `正在将 ${this.gameDetailBasic.gameName} 同步至线上`,
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.5)',
        customClass: 'fullLoading'
      })
      document.querySelector('#app').classList.add('blur-style')
      const { platform, sourceId } = this.gameDetailBasic
      synchronizeSpiderData({ platform, sourceId })
        .then(res => {
          if (res.code === 200) {
            this.loadingClose()
            this.$alert(`游戏 ${this.gameDetailBasic.gameName} 同步成功!`)
          }
        })
    },
    loadingClose () {
      this.fullLoading.close()
      document.querySelector('#app').classList.remove('blur-style')
    }
  }
}
</script>

<style lang="less">
  .blur-style {
    filter: blur(10px);
  }
  .fullLoading {
    .el-icon-loading {
      color: #409eff;
      font-size: 50px;
      margin-bottom: 50px;
    }
    .el-loading-text {
      color: #409eff;
      font-size: 16px;
    }
  }
</style>
<style lang="less" scoped>
  .GameLibraryDetail {
    font-size: 14px;
    header {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;

      .game-cover {
        width: 200px;
        flex-shrink: 0;
        height: 100px;
        border-radius: 4px;
        background-size: cover;
        background-position: center;
      }
      .game-overview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        padding-left: 20px;
        .game-name {
          .icon{
            cursor: pointer;
            margin-left: 20px;
          }
        }
        >div{
          line-height: 30px;
          > .value{
            color: #666;
          }
        }
      }
      }
    }
    // .tabs {
    //   position: sticky;
    //   top: 0px;
    //   background: white;;
    //   z-index: 1000;
    //   height: 50px;
    // }
  }
</style>
