<template>
  <div>
    <EditSection
      v-loading="baseInfo.loading"
      title="基本信息"
      :is-edit="baseInfo.isEdit"
      @edit="handleBaseEdit"
      @confirm="handleBaseConfirm"
      @cancel="handleBaseCancel"
    >
      <el-form
        ref="basic"
        :model="baseInfo.data"
        :rules="baseInfo.rules"
        label-width="100px"
        style="width: 450px"
      >
        <el-form-item
          label="别称："
          prop="aliasName"
        >
          <template v-if="baseInfo.isEdit">
            <el-input
              v-model="baseInfo.data.aliasName"
              placeholder="多个别称请用逗号或分号隔开"
            />
          </template>
          <template v-else>
            <span>{{ gameDetailBasic.aliasName || '/' }}</span>
          </template>
        </el-form-item>
        <el-form-item
          label="开发商："
          prop="developer"
        >
          <template v-if="baseInfo.isEdit">
            <el-input
              v-model="baseInfo.data.developer"
            />
          </template>
          <template v-else>
            <span>{{ gameDetailBasic.developer || '/' }}</span>
          </template>
        </el-form-item>
        <el-form-item
          label="发行商："
          prop="publisher"
        >
          <template v-if="baseInfo.isEdit">
            <el-input
              v-model="baseInfo.data.publisher"
            />
          </template>
          <template v-else>
            <span>{{ gameDetailBasic.publisher || '/' }}</span>
          </template>
        </el-form-item>
        <el-form-item
          label="发行日期："
          prop="releaseDate"
        >
          <template v-if="baseInfo.isEdit">
            <el-date-picker
              v-model="baseInfo.data.releaseDate"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 350px"
              type="date"
              placeholder="选择日期"
            />
          </template>
          <template v-else>
            <span>{{ gameDetailBasic.releaseDate || '/' }}</span>
          </template>
        </el-form-item>
        <el-form-item
          label="游戏分级："
          prop="level"
        >
          <template v-if="baseInfo.isEdit">
            <div class="level">
              <div>
                <span class="levelType">PEGI</span>
                <el-select
                  v-model="baseInfo.data.levelPEGI"
                  style="width: 288px"
                >
                  <el-option
                    v-for="(it, ind) in PEGIList"
                    :key="ind"
                    :label="it.name"
                    :value="it.value"
                  />
                </el-select>
              </div>
              <div>
                <span class="levelType">ESRB</span>
                <el-select
                  v-model="baseInfo.data.levelESRB"
                  style="width: 288px"
                  clearable
                >
                  <el-option
                    v-for="(it, ind) in ESRBList"
                    :key="ind"
                    :label="it.name"
                    :value="it.value"
                  />
                </el-select>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="level">
              <div>
                <span class="levelType">PEGI</span>
                <span>{{ gameDetailBasic.levelPEGI }}</span>
              </div>
              <div>
                <span class="levelType">ESRB</span>
                <span>{{ ESRBMap[gameDetailBasic.levelESRB] || gameDetailBasic.levelESRB }}</span>
              </div>
            </div>
          </template>
        </el-form-item>
      </el-form>
    </EditSection>

    <EditSection
      v-loading="langsInfo.loading"
      class="langs"
      title="语言信息"
      :is-edit="langsInfo.isEdit"
      @edit="handleLangsEdit"
      @confirm="handleLangsConfirm"
      @cancel="handleLangsCancel"
    >
      <el-form :model="langsInfo">
        <el-table
          :data="langsInfo.isEdit ? langsInfo.data : gameDetailLangs"
          border
        >
          <el-table-column>
            <template #default="{row}">
              {{ row.nationLang }}
            </template>
          </el-table-column>
          <el-table-column
            label="界面支持语言"
            align="center"
          >
            <template #default="{row}">
              <el-form-item v-if="langsInfo.isEdit">
                <el-switch
                  v-model="row.interfaces"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="true"
                  :inactive-value="false"
                />
              </el-form-item>
              <i
                v-if="!langsInfo.isEdit && row.interfaces"
                class="fa fa-check checked"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="音频支持语言"
            align="center"
          >
            <template #default="{row}">
              <el-form-item v-if="langsInfo.isEdit">
                <el-switch
                  v-model="row.audio"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="true"
                  :inactive-value="false"
                />
              </el-form-item>
              <i
                v-if="!langsInfo.isEdit && row.audio"
                class="fa fa-check checked"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="字幕支持语言"
            align="center"
          >
            <template #default="{row}">
              <el-form-item v-if="langsInfo.isEdit">
                <el-switch
                  v-model="row.subtitles"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="true"
                  :inactive-value="false"
                />
              </el-form-item>
              <i
                v-if="!langsInfo.isEdit && row.subtitles"
                class="fa fa-check checked"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </EditSection>

    <EditSection
      title="用户数据"
      :show-edit="false"
    >
      <el-table
        :data="userDataList"
        style="width: 600px;"
        border
      >
        <el-table-column prop="description" />
        <el-table-column
          prop="trueValue"
          label="真实值"
        />
        <el-table-column
          prop="showValue"
          label="前端显示"
        />
      </el-table>
    </EditSection>
  </div>
</template>

<script>
import EditSection from '@/views/game-library/components/EditSection.vue'
import { mapGetters, mapMutations } from 'vuex'
import { cloneDeep } from 'lodash'
import {
  editGameBasicInfo,
  editGameLangsInfo
} from '@/api/gameList.js'
export default {
  components: { EditSection },
  data () {
    const levelCheck = (rule, value, callback) => {
      if (!this.baseInfo.data.levelPEGI) {
        callback(new Error('请选择PEGI分级'))
      } else if (!this.baseInfo.data.levelESRB) {
        callback(new Error('请选择ESRB分级'))
      } else {
        callback()
      }
    }

    return {
      PEGIList: [
        { name: '3', value: '3' },
        { name: '7', value: '7' },
        { name: '12', value: '12' },
        { name: '16', value: '16' },
        { name: '18', value: '18' }
      ],
      ESRBList: [
        { name: 'Early Childhood', value: 'ec' },
        { name: 'Everyone', value: 'e' },
        { name: '10+', value: 'e10' },
        { name: 'Teen （13+）', value: 't' },
        { name: '17+', value: 'm' },
        { name: '18+', value: 'ao' },
        { name: 'Rating Pending', value: 'rp' }
      ],
      ESRBMap: {
        ec: 'Early Childhood',
        e: 'Everyone',
        e10: '10+',
        t: 'Teen （13+）',
        m: '17+',
        ao: '18+',
        rp: 'Rating Pending'
      },
      baseInfo: {
        data: {},
        isEdit: false,
        loading: false,
        rules: {
          aliasName: [{ required: true, message: '别名必填' }],
          developer: [{ required: true, message: '开发商必填' }],
          publisher: [{ required: true, message: '发行商必填' }],
          releaseDate: [{ required: true, message: '发布日期必填' }],
          level: [{ required: true, validator: levelCheck }]
        }
      },
      langsInfo: {
        data: [],
        isEdit: false,
        loading: false
      }
    }
  },
  computed: {
    ...mapGetters('gameList', ['gameDetailBasic', 'gameDetailLangs', 'gameDetailPlayStatics']),
    userDataList () {
      const {
        realPlayedNum = 0,
        realWishNum = 0,
        uiPlayedNum = 0,
        uiWishNum = 0
      } = this.gameDetailPlayStatics
      return [
        {
          description: '玩过此游戏的人数',
          trueValue: realPlayedNum,
          showValue: uiPlayedNum
        },
        {
          description: '想要玩此游戏的人数',
          trueValue: realWishNum,
          showValue: uiWishNum
        }
      ]
    }
  },
  methods: {
    ...mapMutations('gameList', ['updateGameDetailBasic', 'updateGameDetailLang']),

    handleBaseEdit () {
      this.baseInfo.data = cloneDeep(this.gameDetailBasic)
      this.baseInfo.isEdit = true
    },
    handleBaseConfirm () {
      this.$refs.basic.validate(valid => {
        if (valid) {
          const basicId = this.gameDetailBasic.basicId
          const {
            aliasName,
            developer,
            publisher,
            releaseDate,
            levelPEGI,
            levelESRB
          } = this.baseInfo.data
          const params = {
            basicId,
            aliasName,
            developer,
            publisher,
            releaseDate,
            levelPEGI,
            levelESRB
          }
          this.baseInfo.loading = true
          editGameBasicInfo(params)
            .then(res => {
              if (res.code === 200) {
                this.updateGameDetailBasic(params)
                this.baseInfo.isEdit = false
              }
            })
            .finally(() => {
              this.baseInfo.loading = false
            })
        }
      })
    },
    handleBaseCancel () {
      this.baseInfo.isEdit = false
      this.$refs.basic.clearValidate()
    },

    handleLangsEdit () {
      this.langsInfo.data = cloneDeep(this.gameDetailLangs)
      this.langsInfo.isEdit = true
    },
    handleLangsConfirm () {
      const basicId = this.gameDetailBasic.basicId
      const items = this.langsInfo.data.map(item => {
        const { id, interfaces, audio, subtitles } = item
        return { id, interfaces, audio, subtitles }
      })
      this.langsInfo.loading = true
      editGameLangsInfo({
        basicId,
        items
      }).then(res => {
        if (res.code === 200) {
          this.updateGameDetailLang(this.langsInfo.data)
          this.langsInfo.isEdit = false
        }
      })
        .finally(() => {
          this.langsInfo.loading = false
        })
    },
    handleLangsCancel () {
      this.langsInfo.isEdit = false
    }
  }
}
</script>

<style lang="less" scoped>
  .levelType {
    display: inline-block;
    width: 50px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: #606266;
  }
  .checked {
    color: #13ce66;
  }
  .langs {
    .el-form-item {
      margin-bottom: 0;
    }
  }
</style>
