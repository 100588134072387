<template>
  <section>
    <EditSection
      title="Steam评论"
      :show-edit="false"
    >
      <div class="page-description">
        上架评论数：{{ listInfo.shelvesnumber }}个
      </div>
      <BHeader style="margin-bottom: 0px;">
        <el-form
          ref="form"
          :inline="true"
          :model="formData"
          @submit.native.prevent
        >
          <el-form-item prop="keyWord">
            <el-select
              v-model="formData.mstatus"
              placeholder="请选择状态"
              clearable
            >
              <el-option
                v-for="(item, index) in orderStatusList"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleSearch"
            >
              搜索
            </el-button>
          </el-form-item>
        </el-form>
      </BHeader>

      <el-table
        v-loading="listInfo.loading"
        :data="listInfo.list"
        stripe
        style="width: 100%"
      >
        <el-table-column
          label="ID"
          prop="id"
        />
        <el-table-column
          label="爬取URL"
          prop="link"
        >
          <template #default="{row}">
            <div
              v-clipboard:copy="row.link"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              class="copy-btn"
            >
              {{ row.link }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="爬取时间"
          prop="createTime"
          width="180"
        />
        <el-table-column
          label="用户昵称"
          prop="nickName"
        />
        <el-table-column
          label="用户头像"
          width="100"
        >
          <template #default="{row}">
            <div
              style="width: 80px; height: 60px;"
            >
              <el-image
                style="width: 100%;height: 100%;border-radius: 12px;"
                :src="row.avatar"
                fit="cover"
              />
              <div class="cover">
                <i
                  class="el-icon-orange video-play"
                  title="Play"
                />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="评论内容"
          prop="content"
        >
          <template #default="{row}">
            <a
              style="text-decoration: underline;color: #8229e5;"
              @click="handleCreateTag(row)"
            >
              点击查看
            </a>
          </template>
        </el-table-column>
        <el-table-column
          prop="mstatus"
          label="总体结论"
        >
          <template #default="{row}">
            <template>
              <span v-if="row.recommend == 1">
                Good
              </span>
              <span v-if="row.recommend == 2">
                Bad
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="认可的人数"
          prop="approbateUserNum"
        />
        <el-table-column
          label="认为有趣的人数"
          prop="joyUserNum"
        />
        <el-table-column
          label="评论人数"
          prop="commentUserNum"
        />
        <el-table-column
          label="总体验时长"
          prop="playTotalTime"
        />
        <el-table-column
          label="评论时体验时长"
          prop="commentPlayTime"
        />
        <el-table-column
          label="发布时间"
          prop="updateTime"
          width="180"
        />
        <el-table-column
          prop="mstatus"
          label="状态"
        >
          <template #default="{row}">
            <template>
              <span v-if="row.mstatus == 2">
                上架
              </span>
              <span v-if="row.mstatus == 3">
                下架
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="200"
          fixed="right"
        >
          <template #default="{row}">
            <el-button
              :type="row.mstatus === 2 ? 'primary': 'danger'"
              :loading="row.loading"
              @click="handleToggleState(row,row.mstatus)"
            >
              {{ row.mstatus === 3 ? '上架' : '下架' }}
            </el-button>
            <el-button
              :loading="row.loading2"
              @click="handleTopping(row)"
            >
              置顶
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="listInfo.total"
        :page.sync="formData.pageNum"
        :limit.sync="formData.pageSize"
        @pagination="queryDataList"
      />

      <el-dialog
        title="评论内容"
        :visible.sync="showDialog"
        width="600px"
        @close="handleDialogClose"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="subForm.content" />
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="showDialog = false">取 消</el-button>
          <el-button @click="showDialog = false">确 定</el-button>
        </span>
      </el-dialog>
    </EditSection>
  </section>
</template>

<script>

import {
  steamCommentList
  , steamUpdateStatus, updateCommentSort
} from '@/api/gameList.js'
import EditSection from '@/views/game-library/components/EditSection.vue'
export default {
  name: 'SteamComment',
  components: { EditSection },
  data () {
    return {
      orderStatusList: [
        { name: '上架', value: 2 },
        { name: '下架', value: 3 }
      ],
      formData: {
        pageNum: 1,
        mstatus: 2,
        sourceId: '', // 271590,
        pageSize: 20
      },
      listInfo: {
        list: [],
        shelvesnumber: 0,
        total: 0,
        loading: false
      },
      showDialog: false,
      subForm: {}
    }
  },
  created () {
    this.formData.sourceId = this.$route.query.sourceId
    this.handleSearch()
  },
  methods: {
    onCopy (e) {
      this.$message.success('内容已复制到剪切板！')
    },
    // 复制失败时的回调函数
    onError (e) {
      this.$message.error('抱歉，复制失败！')
    },
    handleTopping (row) {
      this.$set(row, 'loading2', true)
      updateCommentSort({ id: row.id })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('置顶成功')
            this.queryDataList()
          }
        })
        .finally(() => {
          this.$set(row, 'loading2', false)
        })
    },
    handleToggleState (row, mstatus) {
      if (mstatus === 2) {
        mstatus = 3
      } else if (mstatus === 3) {
        mstatus = 2
      }
      this.$set(row, 'loading', true)
      steamUpdateStatus({ id: row.id, mstatus: mstatus })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('更新成功')
            this.queryDataList()
          }
        })
        .finally(() => {
          this.$set(row, 'loading', false)
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      this.listInfo.loading = true
      steamCommentList(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
            this.listInfo.shelvesnumber = res.data.onlineCount || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleCreateTag (obj) {
      this.showDialog = true
      this.subForm = obj
    },
    handleDialogClose () {
      this.subForm = {}
    }
  }
}
</script>

<style socped>
.page-description {
  font-size: 14px;
  color: #aaa;
  margin: 30px 0;
}
.header {
  font-size: 16px;
  padding: 0 0 20px 20px;
}
.copy-btn {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
