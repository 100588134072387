<template>
  <div class="GameEditVideo">
    <EditSection
      v-loading="videoInfo.loading"
      title="宣传视频"
      :is-edit="videoInfo.isEdit"
      @edit="handleVideoEdit"
      @confirm="handleVideoConfirm"
      @cancel="handleVideoCancel"
    >
      <ul class="video-list">
        <li
          v-for="(item, index) in (videoInfo.isEdit ? (videoInfo.data || []): (gameDetailMedias.promotionalVideo || []))"
          :key="item.id"
          class="video-wrapper"
          :class="{
            edit: videoInfo.isEdit
          }"
          :draggable="videoInfo.isEdit ? true : false"
          @dragover.prevent="handleDragover"
          @dragend.prevent
          @dragstart="handleDragstart(index, $event)"
          @drop.prevent="handleDrop(index, $event)"
        >
          <i
            v-if="videoInfo.isEdit"
            title="Delete"
            class="el-icon-circle-close delete"
            @click="handleDeleteVideo(index)"
          />
          <div class="video-body">
            <el-image
              style="width: 100%"
              :src="item.videoPreviewImage"
            >
              <div
                slot="placeholder"
                class="image-slot"
              >
                <i class="el-icon-loading" />
              </div>
              <div
                slot="error"
                class="image-slot"
              >
                <i class="el-icon-picture-outline" />
              </div>
            </el-image>
            <div class="cover">
              <i
                v-if="videoInfo.isEdit"
                class="el-icon-rank rank"
                title="Move"
              />
              <i
                v-else
                class="el-icon-video-play video-play"
                title="Play"
                @click="handlePlayVideo(item)"
              />
            </div>
          </div>
          <!-- <div class="video-footer">
            16: 03
          </div> -->
        </li>
      </ul>
    </EditSection>

    <el-dialog
      :title="gameDetailBasic.gameName"
      :visible.sync="playVideo"
      :center="true"
      top="80px"
      width="90%"
    >
      <div class="play-wrapper">
        <video
          v-if="playVideo"
          width="100%"
          height="100%"
          controls
        >
          <source :src="currentVidoeURL">
          Your browser does not support video tags
        </video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EditSection from '@/views/game-library/components/EditSection.vue'
import { mapGetters, mapMutations } from 'vuex'
import { cloneDeep } from 'lodash'
import { editGamePromotionalInfo } from '@/api/gameList.js'
export default {
  components: { EditSection },
  data () {
    return {
      playVideo: false,
      currentVidoeURL: '',
      videoInfo: {
        data: {},
        isEdit: false,
        loading: false
      }
    }
  },
  computed: {
    ...mapGetters('gameList', ['gameDetailMedias', 'gameDetailBasic'])
  },
  methods: {
    ...mapMutations('gameList', ['updateGameDetailMediasVideo']),
    handleVideoEdit () {
      this.videoInfo.data = cloneDeep(this.gameDetailMedias.promotionalVideo)
      this.videoInfo.isEdit = true
    },
    handleVideoConfirm () {
      this.videoInfo.loading = true

      const basicId = this.gameDetailBasic.basicId
      const ids = (this.videoInfo.data || []).map(item => item.id)
      const delIds = (this.gameDetailMedias.promotionalVideo || []).filter(item => {
        return !ids.includes(item.id)
      }).map(item => item.id)
      const data = { basicId, ids, delIds }
      editGamePromotionalInfo(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDetailMediasVideo(this.videoInfo.data)
            this.videoInfo.isEdit = false
          }
        })
        .finally(() => {
          this.videoInfo.loading = false
        })
    },
    handleVideoCancel () {
      this.videoInfo.isEdit = false
    },

    handleDragover (event) {
      event.dataTransfer.dropEffect = 'link'
    },
    handleDragstart (index, event) {
      event.dataTransfer.setData('text/plain', JSON.stringify({ sourceIndex: index }))
      event.dataTransfer.dropEffect = 'link'
    },
    handleDrop (index, event) {
      const data = event.dataTransfer.getData('text/plain')
      try {
        const sourceData = JSON.parse(data)
        if (index === sourceData.sourceIndex) return false
        // 暂存activeIndex 所在ID
        const temp = this.videoInfo.data.splice(sourceData.sourceIndex, 1)
        this.videoInfo.data.splice(index, 0, temp[0])
      } catch (e) {
        console.log(e)
      }
    },
    handlePlayVideo (item) {
      this.playVideo = true
      this.currentVidoeURL = item.urls
    },
    handleDeleteVideo (index) {
      this.videoInfo.data.splice(index, 1)
    }
  }
}
</script>

<style lang="less">
  .play-wrapper {
    height: calc(100vh - 250px);
  }
  .GameEditVideo {
    .el-image {
      display: block;
    }
  }
</style>
<style lang="less" scoped>
    .image-slot {
      min-height: 135px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }
  .video-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    .video-wrapper {
      width: 240px;
      margin: 10px;
      position: relative;
      .delete {
        position: absolute;
        color: white;
        top: 0;
        right: 0;
        font-size: 20px;
        z-index: 100;
        cursor: pointer;
        &:hover {
          color: red;
        }
      }
      .video-body {
        width: 100%;
        background-color: #F5F7FA;
        border-radius: 8px;
        background-size: cover;
        overflow: hidden;
        position: relative;
        &.edit {
          cursor: move;
        }
        &:hover {
          .cover {
            display: flex;
          }
        }
        .cover {
          display: none;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border-radius: 8px;

          .video-play,
          .rank {
            color: white;
            font-size: 40px;
          }
          .video-play {
            cursor: pointer;
          }
          .rank {
            cursor: grab;
          }
        }
      }
      .video-footer{
        line-height: 22px;
      }
    }
  }
</style>
