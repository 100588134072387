<template>
  <section>
    <EditSection
      title="关联商品"
      :show-edit="false"
    >
      <el-table
        v-loading="listInfo.loading"
        :data="listInfo.list"
        border
        style="width: 600px"
      >
        <el-table-column
          label="商品ID"
          prop="id"
        />
        <el-table-column
          label="商品名"
          prop="goodsName"
        />
        <el-table-column
          label="价格"
          prop="goodsPrice"
        />
        <el-table-column
          label="操作"
          width="80px"
        >
          <template #default="{row}">
            <BTextButton
              :loading="row.deleteLoading"
              title="删除"
              icon="fa fa-trash-o"
              @click="handleDeleteGoods(row)"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-button
        style="margin: 20px 0; width: 600px;"
        @click="showDialog = true"
      >
        添加商品
      </el-button>
    </EditSection>

    <el-dialog
      title="添加商品"
      :visible.sync="showDialog"
      width="600px"
    >
      <el-form
        ref="subForm"
        :model="subForm"
        :rules="subFormRules"
        @submit.native.prevent
      >
        <el-form-item
          prop="goodsItem"
        >
          <el-select
            v-model="subForm.goodsItem"
            value-key="id"
            style="width: 100%;"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="输入商品ID"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in remoteOptions"
              :key="item.id"
              :label="item.goodsName"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleConfirm"
        >确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import EditSection from '@/views/game-library/components/EditSection.vue'
import {
  searchGoodsList,
  searchGoodsById,
  bindGoodsToGame,
  unbindGoodsWithGame
} from '@/api/gameList.js'

export default {
  name: 'RecommendGoods',
  components: { EditSection },
  data () {
    return {
      listInfo: {
        list: [],
        loading: false
      },
      showDialog: false,
      subForm: {
      },
      subFormRules: {
        goodsItem: [
          { required: true, message: '商品ID必填' }
        ]
      },
      updateLoading: false,
      remoteOptions: [],
      loading: false
    }
  },
  created () {
    this.queryGoodsList()
  },
  methods: {
    queryGoodsList () {
      this.listInfo.loading = true
      searchGoodsList({ sourceId: this.$route.query.sourceId })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleConfirm () {
      this.updateLoading = true
      this.$refs.subForm.validate(valid => {
        if (valid) {
          const { id: goodsId } = this.subForm.goodsItem
          bindGoodsToGame({
            goodsId,
            sourceId: this.$route.query.sourceId
          })
            .then(res => {
              if (res.code === 200) {
                this.$message.success('添加商品成功')
                this.queryGoodsList()
                this.showDialog = false
              }
            })
            .finally(() => {
              this.updateLoading = false
            })
        } else {
          this.updateLoading = false
        }
      })
    },
    handleDeleteGoods (row) {
      this.$confirm('此操作将删除关联商品, 是否继续', '提示')
        .then(() => {
          this.$set(row, 'deleteLoading', true)
          const { id: goodsId } = row
          unbindGoodsWithGame({
            goodsId,
            sourceId: this.$route.query.sourceId
          }).then(res => {
            if (res.code === 200) {
              this.queryGoodsList()
              this.$message.success('删除关联商品成功')
            }
          }).finally(() => {
            this.$set(row, 'deleteLoading', false)
          })
        })
    },
    remoteMethod (query) {
      if (!query) return
      this.loading = true
      searchGoodsById({ goodsId: query })
        .then(res => {
          if (res.code === 200) {
            this.remoteOptions = (res.data.list || []).map(item => {
              return {
                id: item.id,
                goodsName: item.goodsName,
                goodsPrice: item.goodsPrice
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style>

</style>
