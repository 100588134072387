<template>
  <div class="GameEditImage">
    <EditSection
      v-loading="imagesInfo.loading"
      title="宣传图片"
      :is-edit="imagesInfo.isEdit"
      @edit="handleImageEdit"
      @confirm="handleImageConfirm"
      @cancel="handleImageCancel"
    >
      <ul class="image-list">
        <li
          v-for="(item, index) in (imagesInfo.isEdit ? (imagesInfo.data || []): (gameDetailMedias.promotionalImage || []))"
          :key="item.id"
          class="image-wrapper"
          :class="{
            edit: imagesInfo.isEdit
          }"
          :draggable="imagesInfo.isEdit ? true : false"
          @dragover.prevent="handleDragover"
          @dragend.prevent
          @dragstart="handleDragstart(index, $event)"
          @drop.prevent="handleDrop(index, $event)"
        >
          <i
            v-if="imagesInfo.isEdit"
            title="Delete"
            class="el-icon-circle-close delete"
            @click="handleDeleteImage(index)"
          />
          <div class="image-body">
            <el-image
              style="width: 100%"
              :src="item.urls"
            >
              <div
                slot="placeholder"
                class="image-slot"
              >
                <i class="el-icon-loading" />
              </div>
              <div
                slot="error"
                class="image-slot"
              >
                <i class="el-icon-picture-outline" />
              </div>
            </el-image>
            <div
              v-if="imagesInfo.isEdit"
              class="cover"
            >
              <i
                class="el-icon-rank rank"
                title="Move"
              />
            </div>
          </div>
          <!-- <div class="image-footer">
            16: 03
          </div> -->
        </li>
      </ul>
    </EditSection>

    <EditSection
      v-loading="headImageInfo.loading"
      title="封面图（横）"
      :is-edit="headImageInfo.isEdit"
      @edit="handleHeadImageEdit"
      @confirm="handleHeadImageConfirm"
      @cancel="handleHeadImageCancel"
    >
      <div class="content-wrapper">
        <template v-if="!headImageInfo.isEdit && gameDetailMedias.headImage.urls">
          <el-image
            class="images-wrapper"
            style="width: 280px;"
            fit="contain"
            :src="gameDetailMedias.headImage.urls"
          >
            <div
              slot="placeholder"
              class="image-slot"
            >
              <i class="el-icon-loading" />
            </div>
            <div
              slot="error"
              class="image-slot"
            >
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </template>
        <template v-if="headImageInfo.isEdit">
          <UploadImage2
            :show-close="true"
            preview-style="width: 280px;"
            :url.sync="headImageInfo.url"
          />
        </template>
      </div>
    </EditSection>

    <EditSection
      v-loading="headImageVerticalInfo.loading"
      title="封面图（竖）"
      :is-edit="headImageVerticalInfo.isEdit"
      @edit="handleHeadImageVerticalEdit"
      @confirm="handleHeadImageVerticalConfirm"
      @cancel="handleHeadImageVerticalCancel"
    >
      <div class="content-wrapper">
        <template v-if="!headImageVerticalInfo.isEdit && gameDetailMedias.headImageVertical.urls">
          <el-image
            style="width: 150px; height: auto;"
            :src="gameDetailMedias.headImageVertical.urls"
          >
            <div
              slot="placeholder"
              class="image-slot"
            >
              <i class="el-icon-loading" />
            </div>
            <div
              slot="error"
              class="image-slot"
            >
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </template>
        <template v-if="headImageVerticalInfo.isEdit">
          <UploadImage2
            :show-close="true"
            preview-style="width: 150px; height: auto;"
            :url.sync="headImageVerticalInfo.url"
          />
        </template>
      </div>
    </EditSection>

    <EditSection
      v-loading="icon1x1Info.loading"
      title="图标 ( 1 : 1 )"
      :is-edit="icon1x1Info.isEdit"
      @edit="handleIcon1x1InfoEdit"
      @confirm="handleIcon1x1Confirm"
      @cancel="handleIcon1x1Cancel"
    >
      <div class="content-wrapper">
        <template v-if="!icon1x1Info.isEdit && gameDetailMedias.icon1x1.urls">
          <el-image
            style="width: 150px;height: 150px;"
            fit="contain"
            :src="gameDetailMedias.icon1x1.urls"
          >
            <div
              slot="placeholder"
              class="image-slot"
            >
              <i class="el-icon-loading" />
            </div>
            <div
              slot="error"
              class="image-slot"
            >
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </template>
        <template v-if="icon1x1Info.isEdit">
          <UploadImage2
            :show-close="true"
            preview-style="width: 150px; height: 150px;"
            :ratio="[1, 1]"
            :url.sync="icon1x1Info.url"
          />
        </template>
      </div>
    </EditSection>

    <EditSection
      v-loading="icon1x2Info.loading"
      title="图标 ( 1 : 2 )"
      :is-edit="icon1x2Info.isEdit"
      @edit="handleIcon1x2InfoEdit"
      @confirm="handleIcon1x2Confirm"
      @cancel="handleIcon1x2Cancel"
    >
      <div class="content-wrapper">
        <template v-if="!icon1x2Info.isEdit && gameDetailMedias.icon1x2.urls">
          <el-image
            style="width: 140px; height: 280px;"
            fit="contain"
            :src="gameDetailMedias.icon1x2.urls"
          >
            <div
              slot="placeholder"
              class="image-slot"
            >
              <i class="el-icon-loading" />
            </div>
            <div
              slot="error"
              class="image-slot"
            >
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </template>
        <template v-if="icon1x2Info.isEdit">
          <UploadImage2
            :show-close="true"
            preview-style="width: 140px; height: 280px;"
            :ratio="[1, 2]"
            :url.sync="icon1x2Info.url"
          />
        </template>
      </div>
    </EditSection>
  </div>
</template>

<script>
import EditSection from '@/views/game-library/components/EditSection.vue'
import { mapGetters, mapMutations } from 'vuex'
import { cloneDeep } from 'lodash'
import { editGamePromotionalInfo, editGameImage } from '@/api/gameList.js'

export default {
  components: { EditSection },
  data () {
    return {
      imagesInfo: {
        data: {},
        isEdit: false,
        loading: false
      },
      headImageInfo: {
        data: {},
        url: '',
        isEdit: false,
        loading: false
      },
      headImageVerticalInfo: {
        data: {},
        url: '',
        isEdit: false,
        loading: false
      },
      icon1x1Info: {
        data: {},
        url: '',
        isEdit: false,
        loading: false
      },
      icon1x2Info: {
        data: {},
        url: '',
        isEdit: false,
        loading: false
      }
    }
  },
  computed: {
    ...mapGetters('gameList', ['gameDetailMedias', 'gameDetailBasic'])
  },
  methods: {
    ...mapMutations('gameList', [
      'updateGameDetailMediasImage',
      'updateGameDetailMediasHeaderImage',
      'updateGameDetailMediasHeaderImageVertical',
      'updateGameDetailMediasIcon1x1',
      'updateGameDetailMediasIcon1x2'
    ]),
    handleImageEdit () {
      this.imagesInfo.data = cloneDeep(this.gameDetailMedias.promotionalImage)
      this.imagesInfo.isEdit = true
    },
    handleImageConfirm () {
      this.imagesInfo.loading = true

      const basicId = this.gameDetailBasic.basicId
      const ids = (this.imagesInfo.data || []).map(item => item.id)
      const delIds = (this.gameDetailMedias.promotionalImage || []).filter(item => {
        return !ids.includes(item.id)
      }).map(item => item.id)
      const data = { basicId, ids, delIds }
      editGamePromotionalInfo(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDetailMediasImage(this.imagesInfo.data)
            this.imagesInfo.isEdit = false
          }
        })
        .finally(() => {
          this.imagesInfo.loading = false
        })
    },
    handleImageCancel () {
      this.imagesInfo.isEdit = false
    },
    handleDeleteImage (index) {
      this.imagesInfo.data.splice(index, 1)
    },

    handleHeadImageEdit () {
      this.headImageInfo.isEdit = true
      if (this.gameDetailMedias.headImage.urls) {
        this.headImageInfo.url = this.gameDetailMedias.headImage.urls
      }
    },
    handleHeadImageConfirm () {
      this.headImageInfo.loading = true
      const basicId = this.gameDetailBasic.basicId
      const mediaGroup = 'headImage'
      const urls = this.headImageInfo.url
      const data = { basicId, mediaGroup, urls }
      editGameImage(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDetailMediasHeaderImage(urls)
            this.headImageInfo.isEdit = false
          }
        })
        .finally(() => {
          this.headImageInfo.loading = false
        })
    },
    handleHeadImageCancel () {
      this.headImageInfo.isEdit = false
    },

    handleHeadImageVerticalEdit () {
      this.headImageVerticalInfo.isEdit = true
      if (this.gameDetailMedias.headImageVertical.urls) {
        this.headImageVerticalInfo.url = this.gameDetailMedias.headImageVertical.urls
      }
    },
    handleHeadImageVerticalConfirm () {
      this.headImageVerticalInfo.loading = true
      const basicId = this.gameDetailBasic.basicId
      const mediaGroup = 'headImageVertical'
      const urls = this.headImageVerticalInfo.url
      const data = { basicId, mediaGroup, urls }
      editGameImage(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDetailMediasHeaderImageVertical(urls)
            this.headImageVerticalInfo.isEdit = false
          }
        })
        .finally(() => {
          this.headImageVerticalInfo.loading = false
        })
    },
    handleHeadImageVerticalCancel () {
      this.headImageVerticalInfo.isEdit = false
    },

    handleIcon1x1InfoEdit () {
      this.icon1x1Info.isEdit = true
      if (this.gameDetailMedias.icon1x1.urls) {
        this.icon1x1Info.url = this.gameDetailMedias.icon1x1.urls
      }
    },
    handleIcon1x1Confirm () {
      this.icon1x1Info.loading = true
      const basicId = this.gameDetailBasic.basicId
      const mediaGroup = 'icon1x1'
      const urls = this.icon1x1Info.url
      const data = { basicId, mediaGroup, urls }
      editGameImage(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDetailMediasIcon1x1(urls)
            this.icon1x1Info.isEdit = false
          }
        })
        .finally(() => {
          this.icon1x1Info.loading = false
        })
    },
    handleIcon1x1Cancel () {
      this.icon1x1Info.isEdit = false
    },

    handleIcon1x2InfoEdit () {
      this.icon1x2Info.isEdit = true
      if (this.gameDetailMedias.icon1x2.urls) {
        this.icon1x2Info.url = this.gameDetailMedias.icon1x2.urls
      }
    },
    handleIcon1x2Confirm () {
      this.icon1x2Info.loading = true
      const basicId = this.gameDetailBasic.basicId
      const mediaGroup = 'icon1x2'
      const urls = this.icon1x2Info.url
      const data = { basicId, mediaGroup, urls }
      editGameImage(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDetailMediasIcon1x2(urls)
            this.icon1x2Info.isEdit = false
          }
        })
        .finally(() => {
          this.icon1x2Info.loading = false
        })
    },
    handleIcon1x2Cancel () {
      this.icon1x2Info.isEdit = false
    },

    handleDragover (event) {
      event.dataTransfer.dropEffect = 'link'
    },
    handleDragstart (index, event) {
      event.dataTransfer.setData('text/plain', JSON.stringify({ sourceIndex: index }))
      event.dataTransfer.dropEffect = 'link'
    },
    handleDrop (index, event) {
      const data = event.dataTransfer.getData('text/plain')
      try {
        const sourceData = JSON.parse(data)
        if (index === sourceData.sourceIndex) return false
        // 暂存activeIndex 所在ID
        const temp = this.imagesInfo.data.splice(sourceData.sourceIndex, 1)
        this.imagesInfo.data.splice(index, 0, temp[0])
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="less">
.GameEditImage {
  .UploadImage {
    >div {
      display: flex;
    }
  }
  .el-image {
    display: block;
  }
}
</style>
<style lang="less" scoped>
    .image-slot {
      min-height: 135px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }
  .image-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    .image-wrapper {
      width: 240px;
      margin: 10px;
      position: relative;
      .delete {
        position: absolute;
        color: white;
        top: 0;
        right: 0;
        font-size: 20px;
        z-index: 100;
        cursor: pointer;
        &:hover {
          color: red;
        }
      }
      .image-body {
        width: 100%;
        background-size: cover;
        overflow: hidden;
        background-color: #F5F7FA;
        position: relative;
        &.edit {
          cursor: move;
        }
        &:hover {
          .cover {
            display: flex;
          }
        }
        .cover {
          display: none;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          color: white;
          .video-play,
          .rank {
            color: white;
            font-size: 40px;
          }
          .video-play {
            cursor: pointer;
          }
          .rank {
            cursor: grab;
          }
        }
      }
      .image-footer {
        line-height: 22px;
      }
    }
  }
  .content-wrapper {
    box-sizing: border-box;
    padding-left: 52px;
  }
</style>
