<template>
  <div>
    <!-- 游戏名称 -->
    <EditSection
      v-loading="gameNameInfo.loading"
      title="游戏名称"
      :is-edit="gameNameInfo.isEdit"
      @edit="handleGameNameEdit"
      @confirm="handleGameNameConfirm"
      @cancel="handleGameNameCancel"
    >
      <el-form
        ref="gameName"
        :model="gameNameInfo.data"
        label-width="100px"
      >
        <template v-for="(item, index) in (gameNameInfo.isEdit ? gameNameInfo.data : gameDetailDocs)">
          <div :key="index">
            <template v-if="gameNameInfo.isEdit">
              <el-form-item
                :label="langMap[item.lang] || item.lang"
                :prop="`${item.lang}.gameName`"
                :rules="[{required: true, message: `[${langMap[item.lang] || item.lang}] 名称不可为空`}]"
              >
                <el-input
                  v-model="item.gameName"
                  clearable
                />
              </el-form-item>
            </template>
            <template v-else>
              <div class="un-edit">
                <div class="name">
                  {{ langMap[item.lang] || item.lang }}
                </div>
                <div class="value">
                  {{ item.gameName }}
                </div>
              </div>
            </template>
          </div>
        </template>
      </el-form>
    </EditSection>
    <EditSection
      v-loading="synopsisInfo.loading"
      title="简述"
      :is-edit="synopsisInfo.isEdit"
      @edit="handleSynopsisEdit"
      @confirm="handleSynopsisConfirm"
      @cancel="handleSynopsisCancel"
    >
      <el-form
        ref="synopsis"
        :model="synopsisInfo.data"
        label-width="100px"
      >
        <template v-for="(item, index) in (synopsisInfo.isEdit ? synopsisInfo.data : gameDetailDocs)">
          <div :key="index">
            <template v-if="synopsisInfo.isEdit">
              <el-form-item
                :label="langMap[item.lang] || item.lang"
                :prop="`${item.lang}.gameDesc`"
                :rules="[{required: true, message: `[${langMap[item.lang] || item.lang}] 简述不可为空`}]"
              >
                <el-input
                  v-if="synopsisInfo.isEdit"
                  v-model="item.gameDesc"
                  type="textarea"
                  clearable
                  autosize
                  show-word-limit
                />
              </el-form-item>
            </template>
            <template v-else>
              <div class="un-edit">
                <div class="name">
                  {{ langMap[item.lang] || item.lang }}
                </div>
                <div class="value">
                  {{ item.gameDesc }}
                </div>
              </div>
            </template>
          </div>
        </template>
      </el-form>
    </EditSection>
    <EditSection
      v-loading="detailInfo.loading"
      title="详细描述"
      :is-edit="detailInfo.isEdit"
      @edit="handleDetailEdit"
      @confirm="handleDetailConfirm"
      @cancel="handleDetailCancel"
    >
      <el-form
        :model="detailInfo.data"
        label-width="100px"
      >
        <template v-for="(item, index) in (detailInfo.isEdit ? detailInfo.data : gameDetailDocs)">
          <div :key="index">
            <el-form-item>
              <template #label>
                <span style="color: blue;">{{ langMap[item.lang] || item.lang }}</span>
              </template>
              <Tinymce
                v-show="detailInfo.isEdit"
                v-model="item.gameDetail"
                :height="500"
              />
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-show="!detailInfo.isEdit"
                v-html="item.gameDetail"
              />
            </el-form-item>
            <hr style="margin: 100px 0">
          </div>
        </template>
      </el-form>
    </EditSection>
  </div>
</template>

<script>
import EditSection from '@/views/game-library/components/EditSection.vue'
import { mapGetters, mapMutations } from 'vuex'
import { cloneDeep } from 'lodash'
import Tinymce from '@/components/Tinymce/index'
import { editDocs } from '@/api/gameList.js'
export default {
  name: 'GameDocs',
  components: { EditSection, Tinymce },
  data () {
    return {
      langMap: {
        en: '英语',
        zh: '中文简体',
        th: '泰语',
        in: '印尼',
        vi: '越南语',
        ms: '马来语',
        ko: '韩语'
      },
      gameNameInfo: {
        data: {},
        isEdit: false,
        loading: false
      },
      synopsisInfo: {
        data: {},
        isEdit: false,
        loading: false
      },
      detailInfo: {
        data: {},
        isEdit: false,
        loading: false
      }
    }
  },
  computed: {
    ...mapGetters('gameList', ['gameDetailDocs', 'gameDetailBasic'])
  },
  methods: {
    ...mapMutations('gameList', [
      'updateGameDocsGameName',
      'updateGameDocsDesc',
      'updateGameDocsDetail'
    ]),
    handleGameNameEdit () {
      this.gameNameInfo.data = cloneDeep(this.gameDetailDocs)
      this.gameNameInfo.isEdit = true
      this.$nextTick(() => {
        this.$refs.gameName.clearValidate()
      })
    },
    handleGameNameConfirm () {
      this.$refs.gameName.validate(valid => {
        if (valid) {
          this.gameNameInfo.loading = true

          const basicId = this.gameDetailBasic.basicId
          const items = Object.keys(this.gameNameInfo.data).map(lang => {
            const { id, gameName } = this.gameNameInfo.data[lang]
            return { id, gameName }
          })
          const data = { basicId, items }

          editDocs(data)
            .then(res => {
              if (res.code === 200) {
                this.updateGameDocsGameName(this.gameNameInfo.data)
                this.gameNameInfo.isEdit = false
              }
            })
            .finally(() => {
              this.gameNameInfo.loading = false
            })
        }
      })
    },
    handleGameNameCancel () {
      this.gameNameInfo.isEdit = false
    },

    handleSynopsisEdit () {
      this.synopsisInfo.data = cloneDeep(this.gameDetailDocs)
      this.synopsisInfo.isEdit = true
    },
    handleSynopsisConfirm () {
      this.synopsisInfo.loading = true

      const basicId = this.gameDetailBasic.basicId
      const items = Object.keys(this.synopsisInfo.data).map(lang => {
        const { id, gameDesc } = this.synopsisInfo.data[lang]
        return { id, gameDesc }
      })
      const data = { basicId, items }

      editDocs(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDocsDesc(this.synopsisInfo.data)
            this.synopsisInfo.isEdit = false
          }
        })
        .finally(() => {
          this.synopsisInfo.loading = false
        })
    },
    handleSynopsisCancel () {
      this.synopsisInfo.isEdit = false
    },

    handleDetailEdit () {
      this.detailInfo.data = cloneDeep(this.gameDetailDocs)
      this.detailInfo.isEdit = true
    },
    handleDetailConfirm () {
      this.detailInfo.loading = true

      const basicId = this.gameDetailBasic.basicId
      const items = Object.keys(this.detailInfo.data).map(lang => {
        const { id, gameDetail } = this.detailInfo.data[lang]
        return { id, gameDetail }
      })
      const data = { basicId, items }
      editDocs(data)
        .then(res => {
          if (res.code === 200) {
            this.updateGameDocsDetail(this.detailInfo.data)
            this.detailInfo.isEdit = false
          }
        })
        .finally(() => {
          this.detailInfo.loading = false
        })
    },
    handleDetailCancel () {
      this.detailInfo.isEdit = false
    }
  }
}
</script>

<style lang="less" scoped>
  .un-edit {
    display: flex;
    line-height: 30px;
    font-size: 14px;
    margin-bottom: 16px;
    .name {
      text-align: right;
      width: 100px;
      flex-shrink: 0;
      padding-right: 12px;
      box-sizing: border-box;
      color: #606266;
    }
  }
</style>
